
        
    const runtime = require("../../../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["views/elements/charts/timeline/chartTimelineItemPopup.html"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"availabilityList container pt-3\">\n  <div class=\"text-center\">\n    <p>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"ItemID"), env.opts.autoescape);
output += "\n      <br>\n      ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"ItemID")) {
output += "\n        <strong>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"ItemID"), env.opts.autoescape);
output += "</strong>\n      ";
;
}
else {
output += "\n        <i class='fas fa-spinner fa-spin'></i>\n      ";
;
}
output += "\n    </p>\n  </div>\n\n  <table class=\"table table-sm\">\n    <tbody>\n      <tr>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"AvailabilityList_Total"), env.opts.autoescape);
output += "</td>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"Total"), env.opts.autoescape);
output += "</td>\n      </tr>\n      <tr>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"AvailabilityList_InPurchase"), env.opts.autoescape);
output += "</td>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"InPurchase"), env.opts.autoescape);
output += "</td>\n      </tr>\n      <tr>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"AvailabilityList_Blocked"), env.opts.autoescape);
output += "</td>\n        <td class=\"text-warning\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"Blocked"), env.opts.autoescape);
output += "</td>\n      </tr>\n      <tr>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"AvailabilityList_Service"), env.opts.autoescape);
output += "</td>\n        <td class=\"text-warning\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"Service"), env.opts.autoescape);
output += "</td>\n      </tr>\n      <tr>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"AvailabilityList_CheckReturn"), env.opts.autoescape);
output += "</td>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"CheckReturn"), env.opts.autoescape);
output += "</td>\n      </tr>\n      <tr>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"AvailabilityList_Reserved"), env.opts.autoescape);
output += "</td>\n        <td class=\"text-warning\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"Reserved"), env.opts.autoescape);
output += "</td>\n      </tr>\n      <tr>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"AvailabilityList_Commited"), env.opts.autoescape);
output += "</td>\n        <td class=\"text-warning\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"Commited"), env.opts.autoescape);
output += "</td>\n      </tr>\n      <tr>\n        <td>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"AvailabilityList_Rented"), env.opts.autoescape);
output += "</td>\n        <td class=\"text-danger\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"Rented"), env.opts.autoescape);
output += "</td>\n      </tr>\n      <tr>\n        <td>\n          <strong>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"AvailabilityList_Available"), env.opts.autoescape);
output += "</strong>\n        </td>\n        <td class=\"text-success\">\n          <strong>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"Available"), env.opts.autoescape);
output += "</strong>\n        </td>\n      </tr>\n    </tbody>\n  </table>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
          var nunjucks = (runtime && runtime.default || runtime)(
            {"jinjaCompat":false,"isWindows":false,"isAsyncTemplate":false},
            __nunjucks_module_dependencies__
          );

          ctx.__nunjucks_loader_assets__ = __nunjucks_module_dependencies__.assets;

          if (nunjucks.isAsync()) {
            return nunjucks.renderAsync("views/elements/charts/timeline/chartTimelineItemPopup.html", ctx);
          }
        
          return nunjucks.render("views/elements/charts/timeline/chartTimelineItemPopup.html", ctx);
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["views/elements/charts/timeline/chartTimelineItemPopup.html"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
        