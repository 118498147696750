<template>
  <div class="col-xs-12 col-12 chart-timeline">
    <div
      :class="{
        card: true,
        'card-chart': true,
        'h-100': true,
        unavailable,
      }"
    >
      <div class="card-body" v-if="!unavailable">
        <div class="card-entry">
          <b-button
            class="float-right text-grey"
            variant="link"
            v-b-popover.hover.top="diagramData.description"
            :title="diagramData.title"
          >
            <i class="fas fa-question"></i>
          </b-button>

          <h5 class="title">
            <strong>{{ diagramData.title }}</strong>
          </h5>
        </div>
        <b-row>
          <b-col v-for="filter of diagramData.filters" :key="filter.type">
            <div class="float-right">
              <v-select
                multiple
                class="filter-selector"
                :options="filter.options"
                :placeholder="`${translations.Select} ${filter.description}`"
                v-model="labelFilter"
                label="description"
              >
                <template v-slot:option="option">
                  <span>{{ option.description }}</span>
                </template>
                <div slot="no-options">{{ translations.NoRowsFound }}</div>
              </v-select>
            </div>
          </b-col>
        </b-row>

        <timeline
          ref="timeline"
          :items="items"
          :groups="groups"
          :options="options"
          @itemover="onHover"
          v-if="!unavailable"
          style="min-height: 600px; position: relative"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {Timeline} from "vue2vis";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import vSelect from "vue-select";
import {getOrderByID} from "../../../src/services/order";
import {getStockItemMovementItemCounts} from "../../../src/services/item";
import chartTimelineOrderPopup from "../../../views/elements/charts/timeline/chartTimelineOrderPopup.html";
import chartTimelineItemPopup from "../../../views/elements/charts/timeline/chartTimelineItemPopup.html";
import chartTimelineSegmentTitle from "../../../views/elements/charts/timeline/chartTimelineSegmentTitle.html";

dayjs.extend(advancedFormat);

export default {
  name: "chart-timeline",
  components: {
    Timeline,
    "v-select": vSelect,
  },
  props: {
    endpoint: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      diagramData: null,
      unavailable: true,
      groups: [],
      items: [],
      labelFilter: [],
      options: {
        editable: false,
        start: new Date(),
        end: new Date(1000 * 60 * 60 * 10 + new Date().valueOf()),
        stack: true,
        margin: {
          item: {
            horizontal: -1,
          }, // minimal margin between items
          axis: 40, // minimal margin between items and the axis
        },
      },
    };
  },
  created: async function () {
    this.options.start = dayjs().subtract(10, "days").toDate();

    if (this.endpoint) {
      await this.getVisData();
      await this.setVisData();
      this.setVisOptions();

      this.unavailable = false;
    }
  },
  computed: {
    translations: function () {
      return this.$store.state.translations;
    },
    warehouseID: function () {
      return this.$store.state.activeWarehouse;
    },
  },
  watch: {
    labelFilter: async function () {
      await this.getVisData();
      await this.setVisData();
    },
  },
  methods: {
    getVisData: async function () {
      const params = {};

      if (this.labelFilter !== {}) {
        let labels = "";
        for (const label of this.labelFilter) {
          if (labels != "") {
            labels = labels + ",";
          }
          labels = labels + label.value;
        }
        params.labels = labels;
      }
      params.warehouse = this.$store.state.activeWarehouse;

      const result = await this.$axios.get(this.endpoint, {
        params,
      });

      this.diagramData = result.data;
    },
    generateStockMessage: function ({item, startDate, endDate}) {
      return chartTimelineSegmentTitle({
        fullTableName: item.fullTableName,
        criteria: JSON.stringify(item.criteria),
        startDate: startDate.format("HH:mm"),
        endDate: endDate.format("HH:mm"),
        value: item.details || item.value,
      });
    },
    generateOrderSegmentTitle: async function (item) {
      if (!item.criteria.OrderID) return "";
      const orderData = await getOrderByID(item.criteria.OrderID);

      // Ensure we don't render an object for the delivery method but rather it's description.
      if (orderData.DeliveryID) {
        orderData.DeliveryID = orderData.DeliveryID.Description;
      }

      return chartTimelineOrderPopup({
        order: orderData,
        translations: this.translations,
        dayjs,
      });
    },
    onHover: async function ({item}) {
      const targetItem = this.items.find((visItem) => visItem.id === item);
      if (targetItem.isLoaded) {
        return;
      }

      targetItem.title = await this.generateSegmentTitle(targetItem.item);
      // eslint-disable-next-line no-undef
      $(global.session.activeWindow.element)
        .find(".vis-tooltip")
        .html(targetItem.title);
      targetItem.isLoaded = true;
    },
    generateItemSegmentTitle: async function (item) {
      const itemData = await getStockItemMovementItemCounts({
        itemID: item.group,
        startDate: item.start,
        warehouseID: this.warehouseID,
      });
      return chartTimelineItemPopup({
        item: itemData,
        translations: this.translations,
      });
    },
    generateSegmentTitle: async function (item) {
      if (item.criteria && item.criteria.OrderID)
        return await this.generateOrderSegmentTitle(item);
      if (item.subject === "Stock")
        return await this.generateItemSegmentTitle(item);
    },
    setVisData: async function () {
      let segmentCount = 0;

      for (const item of this.diagramData.segments) {
        const startDate = dayjs(item.start);
        const endDate = dayjs(item.end);
        const stockMessage = this.generateStockMessage({
          item,
          startDate,
          endDate,
        });

        let cssClass = "";

        if (item.details == 0 || item.value == 0) cssClass += "nostock";
        this.items.push({
          id: segmentCount,
          group: item.group,
          start: startDate.toDate(),
          end: endDate.toDate(),
          title: chartTimelineItemPopup({
            translations: this.translations,
          }),
          isLoaded: false,
          content: stockMessage,
          className: `${cssClass} segment-${item.subject}`,
          item,
        });
        segmentCount++;
      }

      this.groups = this.diagramData.groups;
    },
    setVisOptions: function () {
      this.options.start = dayjs().format("YYYY-MM-DD 00:01");
      this.options.end = dayjs(this.diagramData.startDisplay, "YYYY-MM-DD")
        .add(1, "day")
        .format("YYYY-MM-DD");
      this.options.min = dayjs(
        this.diagramData.startDisplay,
        "YYYY-MM-DD",
      ).format("YYYY-MM-DD 00:01");
      this.options.max = dayjs(
        this.diagramData.endDisplay,
        "YYYY-MM-DD",
      ).format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");
@import "~vue2vis/dist/vue2vis.css";
@import "vue-select/src/scss/vue-select.scss";
.chart-timeline {
  .vis-timeline {
    position: unset;
  }
}
body {
  background-color: #f2f2f2;

  font-family: "Open Sans", sans-serif !important;

  h1 {
    margin-top: 0;
    font-size: 18.48px;
    color: #2c2c2c;
  }
}
.filter-selector {
  width: 300px;
}
.vis-timeline {
  border: none !important;
  .vis-panel {
    border: none;
  }
  .vis-item {
    border: none !important;
    background-color: #9fe29b !important;
    color: white !important;
    border-radius: 0.5rem !important;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-transition: all 0.3s ease-in-out;
    max-height: 39px;

    font-weight: 600;

    .vis-item-content {
      white-space: nowrap;
      word-break: break-all;
      overflow-wrap: break-word;
    }

    .time {
      font-weight: 400;
    }
  }

  .vis-item:hover {
    background-color: #42bf5f !important;
  }

  .vis-item.retrieval {
    background-color: #d89d08 !important;
  }

  .vis-item.nostock {
    background-color: #f58181 !important;
  }

  .vis-item.nostock:hover {
    background-color: #ff4646 !important;
  }

  .vis-item.outstanding {
    background-color: blue !important;
  }
  .vis-item .vis-item-content {
    white-space: normal;
  }
}
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.pancake-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 39px;
  overflow-y: hidden;
}

.column {
  flex: 1 1 0px; /*  Stretching: */
  flex: 1 1 auto; /*  No stretching: */
  margin: 5px;
}

.stock {
  width: 100%;
}
</style>
