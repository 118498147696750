<template>
  <div class="container-fluid">
    <div class="row no-mar diagrams" v-if="diagramCount > 0">
      <template v-for="diagram in diagrams">
        <chart-textual
          v-if="diagram.DiagramType === 'textual'"
          :title="diagram.Title"
          :key="diagram.Title"
          :endpoint="diagram.Endpoint"
        />
        <chart-pie
          v-if="diagram.DiagramType === 'chart_pie'"
          :title="diagram.Title"
          :key="diagram.Title"
          :endpoint="diagram.Endpoint"
        />
        <chart-radial-bar
          v-if="diagram.DiagramType === 'chart_radial'"
          :title="diagram.Title"
          :key="diagram.Title"
          :endpoint="diagram.Endpoint"
        />
        <chart-bar
          v-if="diagram.DiagramType === 'chart_bar'"
          :title="diagram.Title"
          :key="diagram.Title"
          :endpoint="diagram.Endpoint"
        />
        <chart-timeline
          v-if="diagram.DiagramType === 'chart_timeline'"
          :title="diagram.Title"
          :key="diagram.Title"
          :endpoint="diagram.Endpoint"
        />
      </template>
    </div>
  </div>
</template>

<script>
import chartTextual from "../elements/charts/chartTextual.vue";
import chartPie from "../elements/charts/chartPie.vue";
import chartRadialBar from "../elements/charts/chartRadialBar.vue";
import chartBar from "../elements/charts/chartBar.vue";
import chartTimeline from "../elements/charts/chartTimeline.vue";

export default {
  components: {
    "chart-textual": chartTextual,
    "chart-pie": chartPie,
    "chart-radial-bar": chartRadialBar,
    "chart-bar": chartBar,
    "chart-timeline": chartTimeline,
  },

  computed: {
    diagrams: function () {
      if (this.$store.state.window.output.Data) {
        this.replaceEndpointWarehouse(
          this.$store.state.window.output.Data.Rows,
        );

        return this.$store.state.window.output.Data.Rows;
      }
      return null;
    },
    diagramCount: function () {
      if (this.diagrams === undefined) return 0;
      return this.diagrams?.length ?? 0;
    },
  },
  methods: {
    replaceEndpointWarehouse(diagrams) {
      for (let i = 0; i < diagrams.length; i++) {
        if (!diagrams[i].Endpoint) {
          continue;
        }

        diagrams[i].Endpoint = diagrams[i].Endpoint.replace(
          "warehouse=current",
          "Warehouse=" + this.$store.state.activeWarehouse,
        );
      }
    },
  },
};
</script>

<style></style>
