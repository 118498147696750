<template>
  <div class="col-xs-12 col-sm-12 col-md-4 col-xl-4 textual">
    <div
      :class="{
        card: true,
        'card-chart': true,
        unavailable,
        'h-100': true, // Set height to 100 when unavailable to make sure the div is as high as the rest in it's row
      }"
    >
      <div class="card-body">
        <div class="card-title">
          <b-button
            class="float-right text-grey"
            variant="link"
            v-b-popover.hover.top="diagramData.description"
            :title="diagramData.title"
          >
            <i class="fas fa-question"></i>
          </b-button>
          <h5 class="title">
            <strong>{{ diagramData.title }}</strong>
          </h5>
        </div>
        <div>
          <apexchart
            v-if="diagramSettings.series.length > 0"
            :width="'100%'"
            :type="diagramSettings.chartOptions.chart.type"
            :options="diagramSettings.chartOptions"
            :series="diagramSettings.series"
          ></apexchart>
        </div>
      </div>
      <div class="overlay">
        <div class="text">{{ $store.getters.translations.NoDataFound }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import {formatNumber} from "../../../src/util/formatting/price";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    endpoint: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      unavailable: false,
      diagramData: {},
      diagramSettings: {
        title: "Omzet per periode",
        type: "chart",

        largeColumnSize: 3,
        smallColumnSize: 12,
        series: [],
        chartOptions: {
          chart: {
            type: "bar",
            height: 350,
          },
          labels: [],
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            title: {
              text: "",
            },
            categories: [],
          },
          yaxis: {
            title: {
              text: "",
            },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return formatNumber(val);
              },
            },
          },
        },
      },
    };
  },

  created: async function () {
    if (this.endpoint) {
      const result = await this.$axios.get(this.endpoint);

      this.diagramData = result.data;

      // If unavailable or necessary data is not available show data not available
      if (
        this.diagramData.unavailable ||
        Object.keys(this.diagramData).length === 0 ||
        !this.diagramData.x_axis
      ) {
        this.diagramSettings.series.push(0);
        this.unavailable = true;
        return;
      }

      // Set data
      let dataAsArray = Object.values(this.diagramData.data);

      if (typeof Object.values(this.diagramData.data)[0] !== "object") {
        this.diagramSettings.series.push({
          data: Object.values(this.diagramData.data),

          name: this.diagramData.groups[0] || "",
        });
      } else {
        let objectCount = Object.keys(this.diagramData.data).length;

        for (let i = 0; i < objectCount; i++) {
          this.diagramSettings.series.push({
            data: Object.values(dataAsArray[i]),
            name: this.diagramData.groups[i] || "",
          });
        }
      }

      // Set x Axis labels
      this.diagramSettings.chartOptions.xaxis.categories = Object.values(
        this.diagramData.x_axis.Values,
      );

      // set x axis title
      this.diagramSettings.chartOptions.xaxis.title.text =
        this.diagramData.x_axis.Title || "";

      // set y axis title
      this.diagramSettings.chartOptions.yaxis.title.text =
        this.diagramData.y_axis.Title || "";

      // Set generic theme
      this.diagramSettings.chartOptions.theme = {
        monochrome: {
          enabled: true,
          color: "#f26522",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      };
    }
  },
};
</script>

<style></style>
