import {api} from "./api";
import store from "../../state/store";

export async function getOrderByID(orderID) {
  const result = await api.get(`/v2/orders/${orderID}`, {
    headers: {
      Authorization: `Bearer ${store.state.accessToken}`,
    },
  });

  return result.data;
}
