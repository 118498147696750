export function formatNumber(value) {
  if (isNaN(value)) value = value.replace("€ ", "");

  return prettyPrintNumber(Math.round(parseFloat(value)));
}
export function prettyPrintNumber(value) {
  var suffixes = ["", "k", "m", "b", "t", "qua", "qui"];
  var suffixNum = Math.floor((("" + value).length - 1) / 3);
  var shortValue = parseFloat(
    Math.round(suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value),
  );
  if (shortValue % 1 != 0) {
    shortValue = shortValue.toFixed(1);
  }

  return `${shortValue} ${suffixes[suffixNum]}`;
}
