<template>
  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-2 radialbar">
    <div
      :class="{
        card: true,
        'card-chart': true,
        'h-100': true,
        unavailable,
      }"
    >
      <div class="card-body">
        <b-button
          class="float-right text-grey"
          variant="link"
          v-b-popover.hover.top="diagramData.description"
          :title="diagramData.title"
        >
          <i class="fas fa-question"></i>
        </b-button>
        <h5 class="title">
          <strong>{{ diagramData.title }}</strong>
        </h5>
        <div>
          <apexchart
            v-if="diagramSettings.series.length > 0"
            :type="diagramSettings.chartOptions.chart.type"
            :options="diagramSettings.chartOptions"
            :series="diagramSettings.series"
          ></apexchart>
        </div>
        <div class="radialContext" v-if="this.diagramData.data">
          {{ this.diagramData.data.Value }} / {{ this.diagramData.data.Total }}
        </div>
      </div>

      <div class="overlay">
        <div class="text">{{ $store.getters.translations.NoDataFound }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import {formatNumber} from "../../../src/util/formatting/price";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    endpoint: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      unavailable: false,
      diagramData: {},
      diagramSettings: {
        series: [],
        chartOptions: {
          chart: {
            height: 350,
            type: "radialBar",
            offsetY: -10,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return formatNumber(val);
              },
            },
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 135,

              dataLabels: {
                name: {
                  fontSize: "16px",
                  color: undefined,
                  offsetY: -20,
                },
                value: {
                  offsetY: -10,
                  fontSize: "22px",
                  color: undefined,
                  formatter: function (val) {
                    return `${formatNumber(val)} %`;
                  },
                },
              },
            },
          },
          labels: [""],
        },
      },
    };
  },
  themedOptions: function () {
    const options = this.diagramSettings.chartOptions;

    options.theme = {
      monochrome: {
        enabled: true,
        color: "#f26522",
        shadeTo: "light",
        shadeIntensity: 0.85,
      },
    };

    return options;
  },
  created: async function () {
    if (this.endpoint) {
      const result = await this.$axios.get(this.endpoint);

      this.diagramData = result.data;

      // If unavailable or necessary data is not available show data not available
      if (
        Object.keys(this.diagramData).length === 0 ||
        !this.diagramData.data ||
        this.diagramData.unavailable
      ) {
        this.diagramSettings.series.push(0);
        this.unavailable = true;
        return;
      }

      this.diagramSettings.series.push(
        this.valueTotalToPercentage(
          this.diagramData.data.Value,
          this.diagramData.data.Total,
        ),
      );

      this.diagramSettings.chartOptions.theme = {
        monochrome: {
          enabled: true,
          color: "#f26522",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      };

      this.unavailable = false;
    }
  },
  methods: {
    valueTotalToPercentage: function (value, total) {
      return Math.round(value / (total / 100));
    },
  },
};
</script>

<style lang="scss">
.radialContext {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .radialTextBox {
    flex: 1 1 30%; /*  Stretching: */
    flex: 0 1 30%; /*  No stretching: */
    margin: 5px;
    font-size: 14px;
  }
}
</style>
