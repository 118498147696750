<template>
  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pie">
    <div
      :class="{
        card: true,
        'card-chart': true,
        'h-100': true,
        unavailable,
      }"
    >
      <div class="card-body">
        <b-button
          class="float-right text-grey"
          variant="link"
          v-b-popover.hover.top="diagramData.description"
          :title="diagramData.title"
        >
          <i class="fas fa-question"></i>
        </b-button>
        <h5 class="title">
          <strong>{{ diagramData.title }}</strong>
        </h5>
        <div>
          <apexchart
            v-if="diagramSettings.series.length > 0"
            :width="windowWidth > 800 ? '100%' : '100%'"
            :type="diagramSettings.chartOptions.chart.type"
            :options="diagramSettings.chartOptions"
            :series="diagramSettings.series"
          ></apexchart>
        </div>
      </div>
      <div class="overlay">
        <div class="text">{{ $store.getters.translations.NoDataFound }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {formatNumber} from "../../../src/util/formatting/price";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    endpoint: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      unavailable: true,
      diagramData: {},
      diagramSettings: {
        series: [],
        chartOptions: {
          chart: {
            width: 380,
            height: 120,
            type: "pie",
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return formatNumber(val);
              },
            },
          },

          labels: [],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],

          fill: {
            opacity: 1,
          },
        },
      },
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  created: async function () {
    if (this.endpoint) {
      const result = await this.$axios.get(this.endpoint);

      this.diagramData = result.data;

      // If unavailable or necessary data is not available show data not available
      if (
        this.diagramData.unavailable ||
        Object.keys(this.diagramData).length === 0
      ) {
        this.unavailable = true;
        return;
      }

      this.diagramSettings.series = Object.values(this.diagramData.data);

      this.diagramSettings.chartOptions.labels = Object.keys(
        this.diagramData.data,
      );
      this.diagramSettings.chartOptions.theme = {
        monochrome: {
          enabled: true,
          color: "#f26522",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      };
      this.unavailable = false;
    }
  },
};
</script>

<style></style>
