<template>
  <div class="col-xs-12 col-sm-12 col-md-4 col-xl-2 textual">
    <div
      :class="{
        card: true,
        'card-chart': true,
        'h-100': true,
        unavailable,
      }"
    >
      <div class="card-body" :v-if="diagramData.data">
        <div class="card-entry">
          <b-button
            class="float-right text-grey"
            variant="link"
            v-b-popover.hover.top="diagramData.description"
            :title="diagramData.title"
          >
            <i class="fas fa-question"></i>
          </b-button>

          <h5 class="title">
            <strong>{{ diagramData.title }}</strong>
          </h5>
        </div>

        <div
          class="h-100 d-flex"
          style="align-items: center; justify-content: center"
        >
          <p class="mt-a">{{ formatValue(diagramData.data) }}</p>
        </div>
      </div>
      <div class="overlay">
        <div class="text">{{ $store.getters.translations.NoDataFound }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {formatNumber} from "../../../src/util/formatting/price";

export default {
  props: {
    endpoint: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      unavailable: false,
      diagramData: {},
      id: `tooltip-${Date.now()}`,
    };
  },
  created: async function () {
    if (this.endpoint) {
      const result = await this.$axios.get(this.endpoint);

      this.diagramData = result.data;

      // If unavailable or necessary data is not available show data not available

      if (this.diagramData.unavailable || !this.diagramData.data) {
        this.unavailable = true;
        return;
      }
    }
  },
  methods: {
    formatValue: function (value) {
      if (typeof value === "undefined") return "";
      if (typeof value === "number") return value;
      if (value.includes("€")) return `€ ${formatNumber(value)}`;
      if (value.includes(",")) return formatNumber(value.replace(",", ""));
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  color: #f26522;
}
</style>
